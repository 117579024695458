import Menu, { MenuProps } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

interface StyledMenuProps extends MenuProps {
  ver?: 'top' | 'center' | 'bottom' | number;
  hor?: 'left' | 'center' | 'right' | number;
}

const StyledMenu = styled((props: StyledMenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: props.ver ?? 'bottom',
      horizontal: props.hor ?? 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(1),
    maxHeight: 500,
    minWidth: 180,
    backgroundColor: 'rgba(31, 32, 35, 0.28)',
    border: '1px solid rgba(221, 255, 82, 0.08)',
    backdropFilter: 'blur(32.5px)',
    borderRadius: '16px',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '10px 0'
    },
    '& .MuiTypography-body1': {
      paddingLeft: '10px'
    },
    '& .MuiMenuItem-gutters': {
      padding: '5px 20px',
      margin: '0',
      height: '48px',
      '&:hover': {
        background: 'rgba(221, 255, 85, 0.08)',
        border: '1px solid rgba(221, 255, 82, 0.08)',
        borderLeft: 'none',
        borderRight: 'none'
      }
    },
    '& .MuiMenuItem-root': {
      '&:active': {
        backgroundColor: 'rgba(31, 32, 35, 0.28)'
      }
    }
  }
}));

export { StyledMenu };
