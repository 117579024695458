import * as slice from '.';
import * as api from '../components/shared/api';
import { getclusterListApi } from '../components/unmanagedVmKubernetes/api';
import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';

export function* onGetKubernetesSaving({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => api.getKubernetesSaving(payload));
    yield put(slice.getKubernetesSavingFulfilled(data));
  } catch (e: any) {
    yield put(slice.getKubernetesSavingRejected(e));
  }
}
export function* onGetSimplifyPvCreation(): SagaIterator {
  try {
    const data = yield call(() => api.getSimplifyPvCreation());
    yield put(slice.getSimplifyPvCreationFulfilled(data));
  } catch (e: any) {
    yield put(slice.getSimplifyPvCreationRejected(e));
  }
}
export function* onGetclusterList({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => getclusterListApi(payload));
    yield put(slice.getClusterListFulfilled(data));
  } catch (e: any) {
    yield put(slice.getClusterListRejected(e));
  }
}

// Watcher Saga
export function* kubernetesWatcherSaga(): SagaIterator {
  yield takeEvery('kubernetes/getKubernetesSaving', onGetKubernetesSaving);
  yield takeEvery('kubernetes/getSimplifyPvCreation', onGetSimplifyPvCreation);
  yield takeEvery('kubernetes/getClusterList', onGetclusterList);
}

export default kubernetesWatcherSaga;
