import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import {
  ProductPoliciesReq,
  ProductPoliciesRes,
  ListCloudEntitiesRequest,
  ListCloudEntitiesResponse,
  CloudEntitySetupRequest,
  CloudEntitySetupResponse,
  CloudLevelStatusResponseType,
  CloudEntityStatusesListApiResponseType,
  CloudEntityStatusesListApiPayloadType
} from '../types';

export const getProductPolicies = (
  payload: ProductPoliciesReq
): Promise<ApiResponse<ProductPoliciesRes>> =>
  ApiService.put(`/api/v1/getProductPolicies`, payload);

export const listCloudEntitiesApi = (
  payload: ListCloudEntitiesRequest
): Promise<ApiResponse<ListCloudEntitiesResponse>> =>
  ApiService.put(`/api/v1/listCloudEntities`, payload);

export const cloudEntityOnboardApi = (
  payload: CloudEntitySetupRequest
): Promise<ApiResponse<CloudEntitySetupResponse>> => ApiService.put(`/api/v2/cloudEntityOnboard`, payload);

export const getCloudEntityStatusesListApi = (payload: CloudEntityStatusesListApiPayloadType): Promise<ApiResponse<CloudEntityStatusesListApiResponseType>> => {
  return ApiService.get(`/api/v2/cloudEntityOnboard?cloudProviderCode=${payload.cloudProvider}`);
}

export const getCloudLevelEntityStatusCountApi = (): Promise<ApiResponse<CloudLevelStatusResponseType>> => {
  return ApiService.get('api/v2/cloudEntities')
}