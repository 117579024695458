export const win2012Msg = 'Cannot onboard Windows 2012 with Windows 2019/2022/2010'
export const win2019Msg = 'Cannot onboard Windows 2019 with Windows 2016/2022/2010'
export const winLinuxCombo = 'Cannot onboard windows and linux together'
export const linuxBulkNotSupported = 'Bulk onboarding on Linux is not supported'
export const bulkRootNotSupported =  'Bulk onboarding of root volumes is not supported'
export const cleanUpTextInfo = 'Original volume will be deleted after the onboarding has completed. For Eg. Onboarding completes on Aug 3 then volume will be deleted on Aug 7'
export const nonSupportRootVolume = `Data volume and root volume cannot be onboarded together`

export const msgContent = {
  rebootMsg: [
    `The instance is scheduled to reboot between {startTime} and {endTime}.`,
    `Onboarding of the volume will begin at {onboardTime}. Onboarding will continue after the reboot without impacting the application or the instance.`,
    `Please select a reboot time at least {timeGap} minutes from the current time.`,
    `Onboarding of the volume will begin on {onboardDate} at {onboardTime}. Onboarding will continue after the reboot without impacting the application or the instance.`,
     `Onboarding of the volume will begin on {onboardDate} at {onboardTime}. Onboarding the Machine needs a restart.`
  ],
  rootLinuxAndWindows:
    `Instance requires reboot. Minimum 2 hours of maintenance window required.`,
  common: {
    pastTimeError: 'Invalid time selection. Please choose a future date/time.'
  },
}