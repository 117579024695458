import { createSlice } from '@reduxjs/toolkit';
import { toast as toasts } from 'react-toastify';
import AlertComponenet from 'components/common/AlertComponents';
import { KubernetesSliceType } from '../types';

const initialState: KubernetesSliceType = {
  kubernetesSavingData: undefined,
  simplifyPvCreation: undefined,
  clusterListData: undefined,
  inProcess: undefined,
  inProcessUnmanaged:undefined
};

const kubernetesSlice = createSlice({
  name: 'kubernetes',
  initialState,
  reducers: {
    getKubernetesSaving: (state,{payload}) => {
      state.inProcess = true;
    },
    getKubernetesSavingFulfilled: (state, action) => {
      state.kubernetesSavingData = action.payload;
      state.inProcess = false;
    },
    getKubernetesSavingRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'Kubernetessaving-error' });
      state.inProcess = false;
      state.kubernetesSavingData = undefined;
    },
    getSimplifyPvCreation: (state) => {
      state.inProcess = true;
    },
    getSimplifyPvCreationFulfilled: (state, action) => {
      state.simplifyPvCreation = action.payload;
      state.inProcess = false;
    },
    getSimplifyPvCreationRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'SimplifyPv-error' });
      state.inProcess = false;
      state.simplifyPvCreation = undefined;
    },
    getClusterList: (state, { payload }) => {
      state.inProcessUnmanaged = true;
    },
    getClusterListFulfilled: (state, action) => {
      state.clusterListData = action.payload;
      state.inProcessUnmanaged = false;
    },
    getClusterListRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'KubernetesClusterList-error' });
      state.inProcessUnmanaged = false;
      state.clusterListData = undefined;
    }
  }
});

export const {
  getKubernetesSaving,
  getKubernetesSavingFulfilled,
  getKubernetesSavingRejected,
  getSimplifyPvCreation,
  getSimplifyPvCreationFulfilled,
  getSimplifyPvCreationRejected,
  getClusterList,
  getClusterListFulfilled,
  getClusterListRejected
} = kubernetesSlice.actions;

export default kubernetesSlice.reducer;
