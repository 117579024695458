import { createSlice } from '@reduxjs/toolkit';
import { UnManagedVmsSliceType } from '../types';
import { toast } from 'react-toastify';
import AlertComponenet from 'components/common/AlertComponents';

const initialState: UnManagedVmsSliceType = {
  unmanagedVmsData: null,
  status: 'ready',
  mirrorMetrics: {
    inProgress: null,
    data: null
  },
  modalPreference: null,
  bulkOnboardSchedulePayload: null,
  bulkSchedule: {
    status: 'ready'
  },
  plannedOnboarding: {
    plannedOnboardingData: null,
    status: 'ready'
  },
  scheduleModalPropsList: null,
  mirrorMigration: null
};

const unmanagedVmsSlice = createSlice({
  name: 'unmanagedvm',
  initialState,
  reducers: {
    setMirrorMigrationData: (state, action) => {
      state.mirrorMigration = {
        ...state.mirrorMigration,
        ...action.payload
      }
    },
    setScheduleModalProps: (state, action) => {
      if (action?.payload === null) {
        state.scheduleModalPropsList = null;
      } else {
        state.scheduleModalPropsList = { ...state.scheduleModalPropsList, ...action.payload };
      }
    },
    setBulkOnboardSchedulePayload: (state, action) => {
      state.bulkOnboardSchedulePayload = {
        ...state.bulkOnboardSchedulePayload,
        ...action.payload
      };
    },
    resetBulkOnboardSchedulePayload: (state) => {
      state.bulkOnboardSchedulePayload = null;
    },
    setModalPreference: (state, action) => {
      state.modalPreference = action.payload;
    },

    getUnManagedVm: (state, { payload }) => {
      state.status = 'loading';
      state.unmanagedVmsData = null;
      state.modalPreference = null;
      state.bulkOnboardSchedulePayload = null;
      state.scheduleModalPropsList = null;
    },
    getUnManagedVmSuccess: (state, action) => {
      state.unmanagedVmsData = action.payload;
      state.status = 'success';
    },
    getUnManagedVmError: (state, action) => {
      toast(<AlertComponenet hideHeading msg={action?.payload} />, {
        toastId: 'get-unmanaged-vm-fail'
      });
      state.status = 'error';
      state.unmanagedVmsData = null;
    },

    getMirrorInprogressMetrics: (state, { payload }) => {
      state.mirrorMetrics.inProgress = true;
    },
    geMirrorInprogressMetricsFulfilled: (state, action) => {
      state.mirrorMetrics.data = action.payload;
      state.mirrorMetrics.inProgress = false;
    },
    getMirrorInprogressMetricsRejected: (state, action) => {
      toast(<AlertComponenet hideHeading msg={action?.payload} />, {
        toastId: 'mirror-migration-get-fail'
      });
      state.mirrorMetrics.inProgress = false;
      state.mirrorMetrics.data = null;
    },

    getPlannedOnboarding: (state) => {
      state.plannedOnboarding = { status: 'loading', plannedOnboardingData: null };
    },
    getPlannedOnboardingSuccess: (state, action) => {
      state.plannedOnboarding = { status: 'success', plannedOnboardingData: action.payload };
    },
    getPlannedOnboardingError: (state, action) => {
      toast(<AlertComponenet hideHeading msg={action?.payload} />, {
        toastId: 'planned-onboarding-fail'
      });
      state.plannedOnboarding = { status: 'error', plannedOnboardingData: action.payload };
    },
    resetPlannedOnboarding: (state) => {
      state.plannedOnboarding = {status: 'ready', plannedOnboardingData: null}
    },

    bulkScheduleInit: (state, { payload }) => {
      state.bulkSchedule.status = 'loading';
    },
    createBulkScheduleSuccess: (state, action) => {
      toast(<AlertComponenet title={action?.payload?.title} hideHeading msg={action?.payload?.message} toastType="success" />, {
        toastId: 'create-onboard-schedule-success'
      });
      state.bulkSchedule = { status: 'success', message: action.payload };
    },
    createBulkScheduleError: (state, action) => {
      toast(<AlertComponenet title={action?.payload?.title} hideHeading msg={action?.payload} />, {
        toastId: 'create-onboard-schedule-fail'
      });
      state.bulkSchedule = { status: 'error', message: action.payload };
    },

    updatebulkScheduleInit: (state, { payload }) => {
      state.bulkSchedule.status = 'loading';
    },
    updateBulkScheduleSuccess: (state, action) => {
      toast(<AlertComponenet hideHeading title={action?.payload?.title} msg={action?.payload?.message} toastType="success" />, {
        toastId: 'update-onboard-schedule-fail'
      });
      state.bulkSchedule = { status: 'success', message: action.payload };
    },
    updateBulkScheduleError: (state, action) => {
      toast(<AlertComponenet title={action?.payload?.title} hideHeading msg={action?.payload} />, {
        toastId: 'update-onboard-schedule-fail'
      });
      state.bulkSchedule = { status: 'error', message: action.payload };
    },

    cancelbulkScheduleInit: (state, { payload }) => {
      state.bulkSchedule.status = 'loading';
    },
    cancelBulkScheduleSuccess: (state, action) => {
      toast(<AlertComponenet title={action?.payload?.title} hideHeading msg={action?.payload?.message} toastType="success" />, {
        toastId: 'cancel-onboard-schedule-success'
      });
      state.bulkSchedule = { status: 'success', message: action.payload };
    },
    cancelBulkScheduleError: (state, action) => {
      toast(<AlertComponenet title={action?.payload?.title} hideHeading msg={action?.payload} />, {
        toastId: 'cancel-onboard-schedule-fail'
      });
      state.bulkSchedule = { status: 'error', message: action.payload };
    }
  }
});

export const {
  resetPlannedOnboarding,
  setScheduleModalProps,
  cancelbulkScheduleInit,
  cancelBulkScheduleSuccess,
  cancelBulkScheduleError,
  updatebulkScheduleInit,
  updateBulkScheduleSuccess,
  updateBulkScheduleError,
  getPlannedOnboarding,
  getPlannedOnboardingSuccess,
  getPlannedOnboardingError,
  bulkScheduleInit,
  createBulkScheduleSuccess,
  createBulkScheduleError,
  setModalPreference,
  setBulkOnboardSchedulePayload,
  resetBulkOnboardSchedulePayload,
  getMirrorInprogressMetrics,
  geMirrorInprogressMetricsFulfilled,
  getMirrorInprogressMetricsRejected,
  getUnManagedVm,
  getUnManagedVmSuccess,
  getUnManagedVmError,
  setMirrorMigrationData
} = unmanagedVmsSlice.actions;

export default unmanagedVmsSlice.reducer;
