import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';

interface TableHeaderType {
  handleAllCheck: (e: any) => void;
  interminentCheck: boolean;
  allcheck: boolean;
}

const TableHeader: FC<TableHeaderType> = ({ handleAllCheck, allcheck, interminentCheck }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox checked={allcheck} indeterminate={interminentCheck} onChange={handleAllCheck} />
        </TableCell>
        <TableCell align="left">VM DETAILS</TableCell>
        <TableCell align="left">OS</TableCell>
        <TableCell align="left">Data Volumes</TableCell>
        <TableCell align="left"></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
