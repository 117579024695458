import { format, setSeconds } from 'date-fns';
import { epochToLocalDate } from 'utils/common';
import { BulkOnboardInstancesInfo, BulkOnboardSchedulePayload, ScheduleModalProps } from '../types';

export const getMinDateForSchedule = (scheduleModalProps: ScheduleModalProps | null) => {
  if (scheduleModalProps && scheduleModalProps?.minimumTimeBeforeReboot && scheduleModalProps?.customRebootBasedOnboard) {
    return setSeconds(epochToLocalDate(scheduleModalProps?.minimumTimeBeforeReboot), 0);
  } else {
    return new Date();
  }
};

export const isSetMinDate = (date: string, scheduleModalProps: ScheduleModalProps | null) => {
  if (scheduleModalProps && scheduleModalProps?.minimumTimeBeforeReboot && date && scheduleModalProps?.customRebootBasedOnboard) {
    const selectedDate = format(new Date(date), 'yyyy-MM-dd');
    const minReebootDate = format(epochToLocalDate(scheduleModalProps?.minimumTimeBeforeReboot), 'yyyy-MM-dd');
    if (selectedDate !== minReebootDate) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const getMinimumHoursBeforeReboot = (minTimeBeforeReboot?: number) => {
  if (minTimeBeforeReboot) {
    const currentTime = new Date().getTime() / 1000;
    const rebootTime = minTimeBeforeReboot;
    const hoursUntilReboot = (rebootTime - currentTime) / 3600;
    return Math.ceil(hoursUntilReboot);
  }
  return 1;
};

export const getNumberOfInstanceFromPyaload = (payload: BulkOnboardSchedulePayload) => {
  const uniqueInstanceId = new Set()
  payload.mountPointInfo?.map((instanceInfo: BulkOnboardInstancesInfo) => {
    uniqueInstanceId.add(instanceInfo?.instanceId)
  })
  return uniqueInstanceId.size
}