import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { FC, useState } from 'react';
import { BulkOnboardInstancesInfo, UnManagedVmsRes } from '../../types';
import InnerTable from './InnerTable';

interface TableRowsProps {
  instance: UnManagedVmsRes;
  instanceIndex: number;
  onboardingGroup: string;
  setOnboardingGroup: any;
  selectedPartition: BulkOnboardInstancesInfo[];
  setSelectedPartition: any;
}

const TableRows: FC<TableRowsProps> = ({
  instance,
  instanceIndex,
  onboardingGroup,
  setOnboardingGroup,
  selectedPartition,
  setSelectedPartition
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <TableBody>
      <TableRow
        sx={{
          '& > *': {
            borderBottom: 'none !important',
            backgroundColor: `rgba(31, 32, 35, 0.28)`,
            '&.MuiTableCell-root': {
              '&:not(:first-child-type)': {
                pl: 3.5,
              },
              fontSize: '16px'
            }
          },
          '.MuiTableCell-body': { borderBottom: 'none !important' }
          
        }}>
        <TableCell align="left" sx={{ display: 'flex', pl:'56px' }}>
          <Box>
            <Typography variant="body2" fontSize={16}>
              {instance?.instanceName}
            </Typography>
            <Typography variant="body2" fontSize={16} color="gray">
              {instance.osShortName}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align="left">
          {instance?.percentUtilization}
          <Typography variant="body2" fontSize={16} color="gray">
            {instance?.absoluteUtilization}
          </Typography>
        </TableCell>
        <TableCell align="left">{instance?.savings}</TableCell>
        <TableCell align="left">{instance?.status}</TableCell>        
        <TableCell
          data-testid={`${instance?.instanceName}`}
          align="center"
          onClick={() => setOpen(!open)}
          sx={{
            borderLeft: '1px solid #454546',
            cursor: 'pointer',
           '&.MuiTableCell-root': {
            minWidth:'70px !important' ,
            maxWidth:'70px !important',
           }
          }}>
          <IconButton
            aria-label="expand row"
            size="large"
            sx={{
              height: 28,
              width: 28,
              borderRadius: 99,
              backgroundColor: 'rgba(221, 255, 85, 0.08)',
              color: '#DDFF55'
            }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse
            in={open}
            timeout={10}
            unmountOnExit
            sx={{
              border: '1px solid #454546'
            }}>
            <InnerTable
              instance={instance}
              instanceIndex={instanceIndex}
              onboardingGroup={onboardingGroup}
              setOnboardingGroup={setOnboardingGroup}
              selectedPartition={selectedPartition}
              setSelectedPartition={setSelectedPartition}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default TableRows;
