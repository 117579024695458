import { all, fork } from 'redux-saga/effects';
// local import
import { summaryWatcherSaga } from 'features/summary/store';
import { tenantWatcherSaga } from 'features/auth/store';
import { onboardWatcherSaga } from 'features/onboard/store';
import {
  scalarMetricsWatcherSaga,
  scalerPoliciesWatcherSaga
} from 'features/scalar/components/managedVMs/store';
import { unManagedVmWatcherSaga } from 'features/scalar/components/unmanagedVMs/store';
import { scheduleWatcherSaga } from 'features/scalar/components/shared/store';
import { uploadReportWatcherSaga } from 'features/audit/features/UploadReports/store';
import { getFileListWatcherSaga } from '../features/audit/features/FilesFilters/store';
import { getAuditSummaryWatcherSaga } from '../features/audit/features/DashboardAudit/store';
import { unManagedVmWatcherSagas } from 'features/scalar/components/unManagedVm/store';
import { usersWatcherSaga } from 'features/users';
import {accountWatcherSaga} from 'features/accounts/store'
import { kubernetesWatcherSaga } from 'features/kubernetes/store';

export function* rootSaga() {
  yield all([
    fork(summaryWatcherSaga),
    fork(onboardWatcherSaga),
    fork(tenantWatcherSaga),
    fork(scalarMetricsWatcherSaga),
    fork(unManagedVmWatcherSaga),
    fork(scheduleWatcherSaga),
    fork(uploadReportWatcherSaga),
    fork(getFileListWatcherSaga),
    fork(getAuditSummaryWatcherSaga),
    fork(scalerPoliciesWatcherSaga),
    fork(usersWatcherSaga),
    fork(accountWatcherSaga),
    fork(kubernetesWatcherSaga),
    fork(unManagedVmWatcherSagas),
  ]);
}

export default rootSaga;
